/* Set height to 100% for body and html to enable the background image to cover the whole page: */
body, html {
    height: 100%;
    background-color: #006400;
    margin: 0px;
  }

  .fa-icon {
      border: 2px solid white;
      border-radius: 50%;
      padding: 8px;
      width: 30px;
      height: 30px;
  }
  
  .bgimg {
    /* Background image */
    /* Full-screen */
    height: 100%;
    /* Center the background image */
    background-position: center;
    /* Scale and zoom in the image */
    background-size: cover;
    /* Add position: relative to enable absolutely positioned elements inside the image (place text) */
    position: relative;
    /* Add a white text color to all elements inside the .bgimg container */
    color: white;
    /* Add a font */
    font-family: "Courier New", Courier, monospace;
    /* Set the font-size to 25 pixels */
    font-size: 25px;
  }
  
  /* Position text in the top-left corner */
  .topleft {
    position: absolute;
    top: 0;
    left: 16px;
    margin-top: 16px;
  }
  
  /* Position text in the bottom-left corner */
  .bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
    font-size: 14px;
    text-align: center;
  }
  
  /* Position text in the middle */
  .middle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  
  /* Style the <hr> element */
  hr {
    margin: auto;
    width: 40%;
  }